<template>
  <div id="detail">
    <detail-header></detail-header>
    <router-view></router-view>
  </div>
</template>

<script>
import detailHeader from "@/components/detailHeader";
export default {
  name: "detailIndex",
  components: { detailHeader },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#detail {
  padding-top: 145px;
  padding-bottom: 80px;
}
</style>