<template>
  <div class="detailHeader">
    <div class="logo">
      <img src="@/assets/image/logo.png" alt="" />
    </div>
    <div class="nav_wrap">
      <div
        class="nav_item"
        :class="{ nav_item_active: currentIndex === index }"
        v-for="(item, index) in navList"
        :key="index"
        @click="itemClick(item, index)"
      >
        {{ item.title }}
        <div class="item_mark"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detailHeader",
  data() {
    return {
      currentIndex: 1,
      navList: [
        {
          title: "首页",
          path: "/",
        },
        {
          title: "关于我们",
          path: "/detail/aboutDetail",
        },
        {
          title: "课程介绍",
          path: "/detail/courseDetail",
        },
        {
          title: "优秀作品",
          path: "/detail/worksDetail",
        },
        {
          title: "明星学员",
          path: "/detail/starDetail",
        },
        {
          title: "联系我们",
          path: "/",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val);
        const item = this.navList.find((item) => item.path === val.path);
        if (item) {
          const index = this.navList.indexOf(item);
          this.currentIndex = index;
        }
      },
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    itemClick(item, index) {
      this.currentIndex = index;
      if (index !== 5) {
        this.$router.push(item.path);
      } else {
        this.$router.push({
          path: item.path,
          query: { scroll: true },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.detailHeader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 138px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #12151a;
  z-index: 1000;

  .logo {
    width: 280px;
    height: 60px;
    margin-left: 80px;
  }

  .nav_wrap {
    // width: 660px;
    height: 60px;
    // background-color: rebeccapurple;
    margin-right: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .nav_item {
      margin-left: 48px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      line-height: 19px;
      position: relative;
      cursor: pointer;

      .item_mark {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 33px;
        width: 0;
        height: 0;
        border-left: 4.5px solid transparent;
        border-right: 4.5px solid transparent;
        border-bottom: 6px solid #8afff2;
      }
    }

    .nav_item_active {
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #8afff2;
      font-weight: 600;
      .item_mark {
        display: block;
      }
    }
  }
}
</style>